import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Software/Windows/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Security Eye",
  "path": "/Software/Windows/Security_Eye/",
  "dateChanged": "2023-03-27",
  "author": "Mike Polinowski",
  "excerpt": "Installed on your PC, Security Eye uses IP cameras and webcams to perform unbeatable video monitoring.",
  "image": "./P_SearchThumb_Blue_Iris.png",
  "social": "/images/Search/P_SearchThumb_Blue_Iris.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_S-Security_Eye_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Security Eye' dateChanged='2023-03-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Installed on your PC, Security Eye uses IP cameras and webcams to perform unbeatable video monitoring.' image='/images/Search/P_SearchThumb_Blue_Iris.png' twitter='/images/Search/P_SearchThumb_Blue_Iris.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/Security_Eye/' locationFR='/fr/Software/Windows/Security_Eye/' crumbLabel="Security Eye" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h3 {...{
      "id": "security-eye",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#security-eye",
        "aria-label": "security eye permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Security Eye`}</h3>
    <p>{`To add your INSTAR 2k+ WQHD IP camera to `}<a parentName="p" {...{
        "href": "https://www.security-eye-software.com/"
      }}>{`Security Eye`}</a>{` click on `}<strong parentName="p">{`Add Camera`}</strong>{` and add a `}<strong parentName="p">{`Network (IP) Camera`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/9de948a7b0d8dbf33ec50a96e6c5bf15/Security_Eye_01.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p>{`For WQHD cameras choose to add the IP camera manually:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/3c9c3949a51c8df9eb37252c74ef1540/Security_Eye_06.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Add your cameras IP address and the `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9408_WQHD/Video_Streaming/"
      }}>{`RTSP Streaming URL`}</a>{`:`}</p>
    {/* https://wiki.instar.com/de/Aussenkameras/IN-9408_WQHD/Video_Streaming/ */}
    <p><img parentName="p" {...{
        "src": "/en/c3f8eb20f7ac2330cb009b4114db1b26/Security_Eye_07.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Add your camera login:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/2d2a793ba50343cf0b860c8a0e8c6347/Security_Eye_08.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p>{`When you see your cameras live video click on `}<strong parentName="p">{`Next`}</strong>{` to finish the camera configuration:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/36566c3632e549d46db79ada911e35b1/Security_Eye_09.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/en/dc228398f89da77db0237ff7b2e7c542/Security_Eye_10.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/en/8227e9c2da5d1a30fa4f6d2a00fb091f/Security_Eye_11.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/en/b503665d58e81a053242aeb52fbbaeb6/Security_Eye_12.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "/en/ccf151ed6a535434bac983446e517eaa/Security_Eye_13.png",
        "alt": "Security Eye for your INSTAR IP Camera"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      